import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SWASTHCONFIG } from '../../config';
import { SWASTH_CONST } from '../constant';

const SERVER_URL = SWASTHCONFIG.SERVER_URL;
const PATIENT_URL = SWASTHCONFIG.PATIENT_URL;
const DOCTOR_URL = SWASTHCONFIG.DOCTOR_URL;
const HOSPITAL_URL = SWASTHCONFIG.HOSPITAL_URL;
const NURSE_URL = SWASTHCONFIG.NURSE_URL;
const DX_URL = SWASTHCONFIG.DX_URL;
const PHARMACY_URL = SWASTHCONFIG.PHARMACY_URL;
const MEDICINE_URL = SWASTHCONFIG.MEDICINE_URL;
const PRESCRIPTION_URL = SWASTHCONFIG.PRESCRIPTION_URL;
const LOOKUP_URL = SWASTHCONFIG.LOOKUP_URL;
const VITAL_URL = SWASTHCONFIG.VITAL_URL;
const RECP_URL = SWASTHCONFIG.RECP_URL;
const PRESC_TEMPLATE = SWASTHCONFIG.PRESC_TEMPLATE;
const SEND_MEDICINE = SWASTHCONFIG.SEND_MEDICINE;
const SEND_DXCENTER = SWASTHCONFIG.SEND_DXCENTER;
const SEND_NURSE = SWASTHCONFIG.SEND_NURSE;
const SEND_DOCTOR = SWASTHCONFIG.SEND_DOCTOR;
const APPOINTMENT_URL = SWASTHCONFIG.APPOINTMENT_URL;
const UPLOAD_URL = SWASTHCONFIG.UPLOAD_URL;
const DOWNLOAD_URL = SWASTHCONFIG.DOWNLOAD_URL;
const PAYMENT_URL = SWASTHCONFIG.PAYMENT_URL;
const BILL_URL = SWASTHCONFIG.BILL_URL;
const FOLLOWUP_URL = SWASTHCONFIG.FOLLOWUP_URL;
const SUPPORT_URL = SWASTHCONFIG.SUPPORT_URL;
const S3BASE_URL=SWASTHCONFIG.S3BASE_URL;
const SWASTHPLUS = SWASTHCONFIG.SWASTHPLUS;
const CHATGPT_URL = SWASTHCONFIG.CHATGPT_URL;

@Injectable({
  providedIn: 'root'
})
export class ModulesApisService {

  S3BASE_URL = S3BASE_URL;
  decodedToken:any;
  razorpay_mode = SWASTHCONFIG.RAZORPAYMODE;
  

  constructor(private readonly httpClient: HttpClient) {
    
   }

  getDoctorProfile(docuserID: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${SWASTHCONFIG.DOCTOR_URL}/profile/`+docuserID, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getNurseProfile(nurseuid: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${SWASTHCONFIG.NURSE_URL}/profile/`+nurseuid, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getRecepProfile(recpuid: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${SWASTHCONFIG.RECP_URL}/profile/`+recpuid, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getPharmacyProfile(pharuid: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${SWASTHCONFIG.PHARMACY_URL}/profile/`+pharuid, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getProfileByIdList(profileIdList, tenantId) {
    console.log("profidlist", profileIdList);
    let url = DOCTOR_URL+"/getprofilebyidlist/"+tenantId;
    return this.httpClient.put(url,profileIdList, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getProfileListSuggestionByMobNo(profObj) {
    let url = PATIENT_URL+"/gethospitalptlistforsuggestion";
    return this.httpClient.put(url,profObj, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // Get Server Time
  getServerTime()
  {
    let url = LOOKUP_URL+"/getServerDateTime/";
    return this.httpClient.get(url, {responseType: "text"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getHospitalNameByTenantID(tenantid:any){
    var epurl=HOSPITAL_URL+"/hospitalname/"+tenantid;
    return this.httpClient.get(epurl, {responseType: "text"});
  }

  //Prescription services
  savePrescription(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/add_prescription";
    //console.log("url......"+epurl);
    //console.log(JSON.stringify(values));
    return this.httpClient.post(epurl, values, {responseType: "json"});
  }

  updateVideoChatAppt(update:any, docuid:String, date:any) {
    var epurl=APPOINTMENT_URL+"/updatevchatappt/"+"docuid"+"/"+"date";
    return this.httpClient.post(epurl, update, {responseType: 'json'});
  }

  // doctor prescription share with other doctor
  docPrescShare(shareDetail:any) {
    var epurl=MEDICINE_URL+"/savedocprescsharedetail";
    return this.httpClient.post(epurl, shareDetail, {responseType: 'text'});
  }

  prescriptionVital(dxid: any): Observable<any> {
    var epurl=MEDICINE_URL+"/vital/"+dxid;
    //console.log("url......"+epurl);
    //console.log("dxid----"+(dxid));
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  uniquePrescription(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/unique/"+values;
    //console.log("url......"+epurl);
    //console.log(JSON.stringify(values));
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  //Add QR Code
  saveQrCode(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/addqrcode";
    //console.log("url......"+epurl);
    //console.log(JSON.stringify(values));
    return this.httpClient.post(epurl, values, {responseType: "json"});
  }

  // generate pdf servic side retun key
  pdfGenerate(pdfObj, dxid)
  {
    console.log("pdfObj", pdfObj);
    var epurl=MEDICINE_URL+"/pdfgenerate/"+dxid;
    return this.httpClient.put(epurl, pdfObj, {responseType: 'text'});
  }

  // generate pdf servic side retun key
  pdfGeneratenuo(pdfObj, dxid)
  {
    console.log("pdfObj", pdfObj);
    var epurl=MEDICINE_URL+"/pdfgeneratenuo/"+dxid+"/"+"nuonurse";
    return this.httpClient.put(epurl, pdfObj, {responseType: 'text'});
  }

  // generate pdf servic side retun key
  pdfGeneratepreview(pdfObj)
  {
    console.log("pdfObj", pdfObj);
    var epurl=MEDICINE_URL+"/pdfgeneratepreview";
    return this.httpClient.post(epurl, pdfObj, {responseType: 'text'});
  }

  // Add Notification
  addLocalNotification(values:any){
    var epurl=LOOKUP_URL+"/addlocalnotification/";
    //console.log("url......"+epurl);
    //console.log(JSON.stringify(values));
    //console.log( "dxid-------"+dxid);
    return this.httpClient.post(epurl, values, {responseType: "json"});
  }

  //doctor Notes api
  addDocNotes(values:any){
    var epurl=DOCTOR_URL+"/addnotes";
    //console.log("url......"+epurl);
    //console.log("send Diagnosis --"+JSON.stringify(values));
    return this.httpClient.post(epurl, values, {responseType: "text"});
  }

  addHospitalPatient(hospitalPatientObj:any){
    var epurl=PATIENT_URL+"/addPatientInHospital/";
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, hospitalPatientObj, {responseType: "text"});
  }

  // return user detail using hospital id
  getUserDetailByHospTenantID(hospid:any, tenantid:any){
    var epurl=PATIENT_URL+"/suidbyhtid/"+hospid+"/"+tenantid;
    console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  prescriptionByUserDoc(userid: any, docid:any): Observable<any> {
    var epurl=MEDICINE_URL+"/prescriptionuserdoc/"+userid+"/"+docid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  getPastPrescAndVital(type, userid, docid, tenantid) {
    if(tenantid=='PEAK')
    {
      var epurl=PRESCRIPTION_URL+"/pastprescandvitalbyuserid/"+userid+"/"+tenantid;
    }
    else
    {
      var epurl=PRESCRIPTION_URL+"/pastprescandvital/"+type+"/"+userid+"/"+docid+"/"+tenantid;
    }
    
    console.log("epurl", epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getPastPrescAndVitalByPtuserid(userid,tenantid) {
    var epurl=PRESCRIPTION_URL+"/pastprescandvitalbyuserid/"+userid+"/"+tenantid;
    console.log("epurl", epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getPrescTemplateAndShareDocList(docid, tenantid) {
    var epurl=PRESCRIPTION_URL+"/presctemplateandsharedoclist/"+docid+"/"+tenantid;
    console.log("epurl", epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  patientCheck(ptID: any){
    ////console.log("user---"+ptID);    
    return this.httpClient.get(`${PATIENT_URL}/user_detail/`+ptID, {responseType: "json"});
  }

  //return 
  getHospIdByUserTenantID(userid:any, tenantid:any):Observable<any>{
    var epurl=PATIENT_URL+"/hospidbytuid/"+tenantid+"/"+userid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  getVitalByAppt(apptid:any){
    var epurl=VITAL_URL+"/vitalByAppt/"+apptid;
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getVitalByRecentDate(userid){
    var epurl=VITAL_URL+"/vitalByRecentDate/"+userid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  getVitalList(membuid,userid,uhid, tenantId){
    var epurl=VITAL_URL+"/getvitallist/"+membuid+"/"+userid+"/"+uhid+"/"+tenantId;
    return this.httpClient.get(epurl, {responseType: "json"})
    // .pipe(
    //   catchError((err) => {
    //     SWASTH_CONST.apiErrorHandling(err);
    //     return throwError(err); 
    //   })
    // )
  }

  getPtVitalTrackList(membuid,userid,uhid, tenantId){
    var epurl=VITAL_URL+"/getptvitaltracklist/"+membuid+"/"+userid+"/"+uhid+"/"+tenantId;
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // billing APis
  // add bill without amountt list
  addBilling(billobj:any){
    var epurl=BILL_URL+"/addbill";
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, billobj, {responseType: "json"});
  }

  // add bill without amountt list
  addBillingBranch(billobj:any){
    var epurl=BILL_URL+"/addbillbranch";
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, billobj, {responseType: "json"});
  }

  //vital servcies
  saveVital(values: any) {
    var epurl=VITAL_URL+"/add_vital";
    return this.httpClient.post(epurl, values, {responseType: "text"});
  }

  /* update appointment   */
  updateApptByID(updateObj:any): Observable<any> {
    var epurl=APPOINTMENT_URL+"/updatestatus";
    //console.log("url......"+epurl);    
    return this.httpClient.put(epurl, updateObj, {responseType: "json"});
  }

  //Get doctors list tenant id
  doctorsListByTenantID(tid:any): Observable<any> {
    var epurl=DOCTOR_URL+"/profilelist/"+tid;
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  //Get doctors list tenant id
  doctorsListByTenantIDbranch(tid:any,branchcode:any): Observable<any> {
    var epurl=DOCTOR_URL+"/profilelistbranch/"+tid+"/"+branchcode;
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  savePrescriptionTemplate(values:any): Observable<any> {
    var epurl=PRESCRIPTION_URL+"/savetemplate";
    //console.log("url......"+epurl);
    //console.log("Template Prescription id--"+JSON.stringify(values));
    return this.httpClient.post(epurl, values, {responseType: "json"});
  }

  // save swasthplus prescription
  saveSwasthPlusPrescription(prescObj) {
    var epurl=PRESCRIPTION_URL+"/saveswasthplusprescription/";
    //console.log("url......"+epurl);
    //console.log("Template Prescription id--"+JSON.stringify(values));
    return this.httpClient.post(epurl, prescObj, {responseType: "json"});
  }

  // Prescription Template
  prescTemplateList(docuserID:any): Observable<any> {
    var epurl=PRESC_TEMPLATE+"/templatesbydoctor/"+docuserID;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  templatePrescription(value:any): Observable<any> {
    var epurl=PRESC_TEMPLATE+"/prescription/"+value;
    //console.log("url......"+epurl);
    //console.log("Template Prescription id--"+value);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // create patient profile and login by doctor from prescription(lockdown)
  createPtUserID(postData: any) 
  {
    var epurl=PATIENT_URL+"/signupfrpresc";
    console.log(JSON.stringify(postData));
    return this.httpClient.post(epurl, postData, {responseType: 'text'});
  }

  getHospitalDetailsByTenantid(tenantid:any){
    var epurl=HOSPITAL_URL+"/hospitalprofile/"+tenantid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get doctor multiple profile by mobile number
  getDocprofilebymobno(mobileno): Observable<any> {
    var epurl=DOCTOR_URL+"/docprofilebymobno/"+mobileno;
    console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get doctor multiple profile by mobile number
  getDocprofilebymobnonew(mobileno:any,tenantid:any): Observable<any> {
    var epurl=DOCTOR_URL+"/docprofilebymobnonew/"+mobileno+"/"+tenantid;
    console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // create followup circle
  createNewCircle(circleObj: any){
    console.log("circle obj---",circleObj); 
    let url = FOLLOWUP_URL+"/addcircle";
    return this.httpClient.post(url, circleObj, {responseType: "json"});
  }

  // join to virtual hospital circle
  JoinToCircle(circleDocObj: any, ccode, cpass){
    console.log("circle obj---",circleDocObj); 
    let url = FOLLOWUP_URL+"/joincircle/"+ccode+"/"+cpass;
    return this.httpClient.put(url, circleDocObj, {responseType: "json"});
  }

  // get circle detail for doctor
  getCircleDetail(circleCode) {
    let url = FOLLOWUP_URL+"/getallcircle/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get circle page detail for doctor
  getCirclePageDetail(circleCode, docid) {
    let url = FOLLOWUP_URL+"/getcpagedetail/"+circleCode+"/"+docid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // create chat
  createChat(chatObj: any){
    console.log("chat obj---",chatObj); 
    let url = LOOKUP_URL+"/scaddchatsc";
    return this.httpClient.post(url, chatObj, {responseType: "json"});
  }

  // upload to s3
  uploadFileToS3(uploadObj) {
    let url = UPLOAD_URL + '/api/file/lockdownupload';
    return this.httpClient.post(url, uploadObj, {responseType: "text"});
  }

  getDoctorCircleList(douid) {
    let url = FOLLOWUP_URL+"/getdoctorcircles/"+douid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // create followups plans
  createFollowupsPlan(planfeadiagObj) {
    var epurl=FOLLOWUP_URL+"/savefollowupsplan/";
    return this.httpClient.post(epurl, planfeadiagObj, {responseType: 'text'});
  }

  // get followup consultation time template by specialization duration
  getFollowupsConsulTimeTemplate(spec, duration): Observable<any> {
    var epurl=FOLLOWUP_URL+"/fupctsprcdur/"+spec+"/"+duration;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // get followup plans by circle code doctor uid
  getFollowupsPlanByDocCcode(ccode, docuid): Observable<any> {
    var epurl=FOLLOWUP_URL+"/allplanbyccode/"+ccode+"/"+docuid;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // update followups plans
  updateFollowupsPlan(planfeadiagObj) {
    var epurl=FOLLOWUP_URL+"/updatefollowupsplan/";
    return this.httpClient.post(epurl, planfeadiagObj, {responseType: 'text'});
  }

  // download document as base64 by keyname
  downloadDocumentAsBase64ByKeyname(keyname:any)
  {
    var pf_url=DOWNLOAD_URL+"/api/prescriptionfile/"+keyname;
    //console.log(pf_url);
    // debugger;       
    return this.httpClient.get(pf_url, {responseType: "text"});
  }

  // update followup clinical appointment paymeny status an installment amount
  updateFollowClinicalApptPaymentAmtAndst(apptobj)
  {
    let url = SWASTHPLUS+"/updatefapptamtst";
    return this.httpClient.post(url, apptobj, {responseType: "text"});
  }

  //	save tagged appointments and diagnosis for patient
  saveTaggedApptsDiag(tagApptDiagObj): Observable<any> {
    var epurl=FOLLOWUP_URL+"/savetagappt";
    return this.httpClient.post(epurl, tagApptDiagObj, {responseType: "json"});
  }

  // save followup clinic appt
  saveFollowUpClinicAppt(apptList) {
    let url = SWASTHPLUS+"/savefollowupclinicappt";
    return this.httpClient.post(url,apptList, {responseType: "text"});
  }

  // delete followup clinic appt
  deleteFollowUpClinicAppt(appt) {
    let url = SWASTHPLUS+"/deletefollowupclinicappt";
    return this.httpClient.post(url,appt, {responseType: "text"});
  }

  // get followup clinic appt
  getFollowUpClinicAppt(ptUid, circCode) {
    let url = SWASTHPLUS+"/getptfollowupappt/"+ptUid+"/"+circCode;
    return this.httpClient.get(url,{responseType: "json"});
  }

  // Send Video Appointment SMS to Doctor
  sendHandbookSMSToPatient(ptMobile, patientName, doctorName, websiteLink) {
    var epurl=LOOKUP_URL+"/sendhandbooksms/"+ptMobile+"/"+patientName+"/"+doctorName+"/"+websiteLink;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }


  // lockdown upload Prescription
  uploadPrescriptionToS3(body){
    var epurl=UPLOAD_URL + '/api/file/lockdownupload';
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, body, {responseType: 'text'});
  }

  // update pdf keyname for followup handbook
  updatehandbookKey(hbObj): Observable<any> {
    var epurl=LOOKUP_URL+"/scupatekeyofhbsc";
    return this.httpClient.post(epurl, hbObj, {responseType: 'json'});
  }

  // Update Patient hand book followup and appointment
  updateHandBookApptPayst(apptobj,st)
  {
    let url = LOOKUP_URL+"/updatehbapptpayst/"+st;
    return this.httpClient.put(url, apptobj, {responseType: "json"});
  }

  // update follow up plan status
  updateFollowupPlanStatus(planid, status): Observable<any> {
    var epurl=FOLLOWUP_URL+"/planstupdate/"+planid+"/"+status;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // edit circle name by admin doc
  editCircleName(circObj) {
    let url = FOLLOWUP_URL+"/editcirclename";
    return this.httpClient.put(url, circObj, {responseType: "text"});
  }

  // Deactivate Member Doctor
  deactivateMemberDoctor(docuserid:any, circlecode:any) {
    var epurl=FOLLOWUP_URL+"/deactivatememdoctor/"+docuserid+"/"+circlecode;
    return this.httpClient.put(epurl, {responseType: 'text'});
  }

  // get circle member active doctor list
  getActiveMemberCircleDocList(circleCode) {
    let url = FOLLOWUP_URL+"/getcircleactivememdoclist/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get circle entity by circle code
  getCircleEntity(circleCode) {
    let url = FOLLOWUP_URL+"/getcircle/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get triage doctor patient list
  getTriageDocPtList(docuid,circleCode) {
    let url = LOOKUP_URL+"/sctriagepatientlistsc/"+ docuid +"/"+ circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get patient chat by docuserid and circle code
  getPatientChatByPatientUserid(userid,circleCode) {
    let url = LOOKUP_URL+"/scgetpatientchatsc/"+userid+"/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get prescription by userid, circle code
  prescriptionByUserCircle(userid: any, ccode:any): Observable<any> {
    var epurl=MEDICINE_URL+"/prescriptionusercirclecode/"+userid+"/"+ccode;
    console.log("url......"+epurl);
    console.log(JSON.stringify(userid, ccode));
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get triage doctor chat detail
  getPtTriageDocChatDetail(uid,docid,ccode) {
    let url = LOOKUP_URL+"/scgettriagedoctorchatsc/"+uid+"/"+docid+"/"+ccode;
    console.log(url);    
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get prescription by userid, docid, circle code
  prescriptionByUserDocCircle(userid: any, docid:any, ccode:any): Observable<any> {
    var epurl=MEDICINE_URL+"/prescriptionuserdocccode/"+userid+"/"+docid+"/"+ccode;
    console.log("url......"+epurl);
    console.log(JSON.stringify(userid, docid, ccode));
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // Send Test Followup SMS to Doctor
  sendTestFollowupSmstoDoctor(drMobile, doctorName)
  {
    var epurl=LOOKUP_URL+"/folowupdoctor/"+drMobile+"/"+doctorName;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // Send Test Followup SMS to Doctor
  sendTestFollowupSmstoPatient(drMobile, doctorName)
  {
    var epurl=LOOKUP_URL+"/folowupatient/"+drMobile+"/"+doctorName;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  getFollowUpAppointment(docuid, date) {
    var epurl=LOOKUP_URL+"/getfollowupappt/"+docuid+"/"+date;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // get all circle  video appt of doctor
  getAllCircleVideoApptOfDoc(docuid, date) {
    var epurl=LOOKUP_URL+"/getallcirclevideoapptofdoctor/"+docuid+"/"+date; 
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  //	check tagging created for patient by userid ccode docuserid planid
  checkTaggingForPatient(ptuid,planid,docuid,ccode): Observable<any> {
    var epurl=FOLLOWUP_URL+"/checktagforpt/"+ptuid+"/"+docuid+"/"+planid+"/"+ccode;
    console.log("hffy->",epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  //	get plan details, followup, diagnosis for tagging
  getplanfollowupForTagging(planid,docuid,ccode): Observable<any> {
    var epurl=FOLLOWUP_URL+"/getplanfollowdiagnosistag/"+planid+"/"+docuid+"/"+ccode;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // update circle detail
  updateCircleDetail(circObj) {
    let url = LOOKUP_URL+"/updatecircledetail";
    return this.httpClient.put(url, circObj, {responseType: "json"});
  }

  // get mobile country code list
  getCountryCodes() {
    var epurl=LOOKUP_URL+"/sccountrycodessc";
    return this.httpClient.get(epurl, {responseType: "json"});
  }
  // get circle doctor list
  getCircleDocList(circleCode) {
    let url = FOLLOWUP_URL+"/getcircledoclist/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get follow up circle all doctors profile and chat room key role
  getCircleAllDocProfileAndCircleKeyRole(circleCode): Observable<any> {
    let url = FOLLOWUP_URL+"/getalldocprokeyrole/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  getDocVideoTimeSessionForWeek(obj) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/getdocvideotimesession";
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  getDocClinicTimeSessionForWeek(obj) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/getdocclinictimesession";
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  // get Doctor RTC Token
  getDoctorRTCToken(doctoruid,channelName)
  {
    var epurl=LOOKUP_URL+"/getrtctokendoctor/"+doctoruid+"/"+channelName;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // get Patient RTC Token
  getPatientRTCToken(patientuid,channelName)
  {
    var epurl=LOOKUP_URL+"/getrtctokenpatient/"+patientuid+"/"+channelName;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // get Doctor RTM Token
  getDoctorRTMToken(doctoruid)
  {
    var epurl=LOOKUP_URL+"/getrtmtokendoctor/"+doctoruid;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // get Patient RTM Token
  getPatientRTMToken(patientuid)
  {
    var epurl=LOOKUP_URL+"/getrtmtokenpatient/"+patientuid;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // save agora video call details
  saveAgoraVideoCallDetails(agoraObj){
    var epurl=LOOKUP_URL+"/saveagoradetails";
    console.log("url......"+epurl);
    return this.httpClient.post(epurl, agoraObj, {responseType: "json"});
  }

  // Deactivate Member Doctor
  updateAgoraStatus(circlecode:any,doctoruid:any,patientuid:any,status:any) {
    var epurl=LOOKUP_URL+"/updateagoracallstatus/"+circlecode+"/"+doctoruid+"/"+patientuid+"/"+status;
    return this.httpClient.put(epurl, {responseType: 'text'});
  }

  // send push notification to patient
  sendPatientPushNotification(notifobj) {
    let url = LOOKUP_URL+"/circlepatientsend";
    return this.httpClient.post(url, notifobj, {responseType: "text"});
  }

  // send Web push notification to patient
  sendPatientWebPushNotification(notifobj) {
    let url = LOOKUP_URL+"/webpushnotification";
    return this.httpClient.post(url, notifobj, {responseType: "text"});
  }
  getDocVideoTimeSession(obj) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/getdocvideotimesessionbydateorweek";
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  getDocClinicTimeSession(obj) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/getdocclinictimesessionbydateorweek";
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  resetAllSlotSessionOfDoctor(docuid:any, tenantid:any) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/resetallslotsessionofdoctor/"+docuid+"/"+tenantid;
    return this.httpClient.delete(epurl, {responseType: "text"});
  }

  savePtHospitalProfile(profObj, type) {
    let url = SWASTHCONFIG.PATIENT_URL+"/savepthospitalprofile/"+type;
    return this.httpClient.post(url, profObj, {responseType: "json"});
  }

  updatePtHospitalProfile(profObj, type, newuhid, olduhid) {
    let url = SWASTHCONFIG.PATIENT_URL+"/updatepthospitalprofile/"+type+"/"+newuhid+"/"+olduhid;
    return this.httpClient.post(url, profObj, {responseType: "json"});
  }

  updateHospitalPtProfile(profObj) {
    let url = SWASTHCONFIG.PATIENT_URL+"/updatehospitalptprof";
    return this.httpClient.post(url, profObj, {responseType: "json"});
  }

  checkUhidExist(uhid, tenantid) {
    var epurl=PATIENT_URL+"/chkuhidexist/"+uhid+"/"+tenantid;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  saveDocVideoTimeSession(obj, day){
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/savedoctorvideotimesession/"+day;
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  editTimeSession(obj) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/editdoctortimesession";
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  editSingleDayTimeSession(obj, date) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/editdoctortimesessionsingleday/"+date;
    console.log("url......"+epurl);
    return this.httpClient.post(epurl, obj, {responseType: "text"});
  }


  saveDocClinicTimeSession(obj, day){
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/savedoctorclinictimesession/"+day;
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  saveAddedSessionConsultTime(obj, type) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/addmorevideotimesession/"+type;
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  saveClinicAppt(apptobj) {
    let url = SWASTHCONFIG.SWASTHPLUS+"/saveclinicappt";
    return this.httpClient.post(url, apptobj, {responseType: "json"});
  }

  saveClinicApptSchedular(apptobj) {
    let url = SWASTHCONFIG.SWASTHPLUS+"/saveclinicschedulerappt";
    return this.httpClient.post(url, apptobj, {responseType: "json"});
  }

  saveAddedClinicSessionConsultTime(obj, type) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/addmoreclinictimesession/"+type;
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  saveAddedSeparateSessionConsultTime(obj, date) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/addmoreclinictimesessionsingleday/"+date;
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  removeDocTimeSession(obj, type, date) {
    var epurl=SWASTHCONFIG.SWASTHPLUS+"/deletedoctortimesession/"+type+"/"+date;
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  // get Patient Profile
  getPatientProfile(patientuid)
  {
    var epurl=PATIENT_URL+"/user_detail/"+patientuid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // get followup patient doctor chat detail 
  getFollowupPtDocChatDetail(obj:any): Observable<any> {
    let url = FOLLOWUP_URL+"/getfupptdocchatdetail";
    return this.httpClient.put(url, obj, {responseType: "json"});
  }

  ptChatDeactiveReduceCount(deactiveob:any) {
    let url = FOLLOWUP_URL+"/deactiveptchat";
    return this.httpClient.put(url, deactiveob, {responseType: "text"});
  }  

  // update prescription medicine
  updateMedicine(values:any, dxid: any){
    var epurl=MEDICINE_URL+"/edMedicine/"+dxid;
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, values, {responseType: "json"});
  }

  //pharmacy medicine 
  removeMedicine(mid=[], dxid:any){
    var epurl=MEDICINE_URL+"/rmMedicine/"+mid+"/"+dxid;
    // console.log("url......"+epurl);
    return this.httpClient.delete(epurl, {responseType: "json"});
  }

  // update prescription test
  updateTest(values:any, dxid: any){
    var epurl=MEDICINE_URL+"/edtest/"+dxid;
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, values, {responseType: "json"});
  }

  // remove prescription test
  removeTest(tid=[], dxid:any){
    var epurl=MEDICINE_URL+"/rmLab/"+tid+"/"+dxid;
    // console.log("url......"+epurl);
    return this.httpClient.delete(epurl, {responseType: "json"});
  }

  // update prescription
  updatePrescription(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/updatePrescription";
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, values, {responseType: "text"});
  }

  // update prescription vital 
  prescriptionVitalUpdate(vital: any): Observable<any> {
    var epurl=MEDICINE_URL+"/vitalupd";
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, vital, {responseType: "json"});
  }

  // update vital
  updateVital(values: any) {
    var epurl=VITAL_URL+"/vital_update";
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, values, {responseType: "text"});
  }

  // get qrcode by prescription id 
  getQrCode(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/getqrcode/"+values;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl,{responseType: "json"});
  }

  // get document detail
  getDocumentDetails(documentID:any){
    var epurl=UPLOAD_URL+"/getdocumentdetails/"+documentID;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // download document by keyname
  downloadDocumentByKeyname(keyname:any) {
    var pf_url=DOWNLOAD_URL+"/api/file/"+keyname;
    //console.log(pf_url);
    return this.httpClient.get(pf_url, {responseType: "text"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get document by diagnosis id
  getDocumentsByDxid(dxid) {
    var pf_url=UPLOAD_URL+"/view_documents/"+dxid;
    //console.log(pf_url);
    return this.httpClient.get(pf_url, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // create bill for virtual consultation
  createBillForConsultation(billobj:any) {
    console.log("chat obj---",billobj); 
    let url = FOLLOWUP_URL+"/createbillforconsult";
    return this.httpClient.post(url, billobj, {responseType: "text"});
  }

  // get prescription vital by uid and circle code
  prescriptionVitalByUidCcode(uid: any, tenantid:any): Observable<any> {
    var epurl=MEDICINE_URL+"/vitaluidfrcircle/"+uid+"/"+tenantid;
    console.log("url......"+epurl);
    //console.log("dxid----"+(dxid));
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get prescription vital by uid and circle code and family member id 
  prescriptionVitalByUidCcodeFormember(uid: any, tenantid:any,fmid:any): Observable<any> {
    var epurl=MEDICINE_URL+"/vitaluidfrcirclefamilymember/"+uid+"/"+tenantid+"/"+fmid;
    console.log("url......"+epurl);
    //console.log("dxid----"+(dxid));
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // update circle patient allergies
  updatePtAllergies(ptuid: any, allergy) {        
    var epurl=LOOKUP_URL+"/scupdatecirclepatientallergysc/"+ptuid+"/"+allergy;
    console.log("update allergy api-->",epurl);       
    return this.httpClient.put(epurl, {responseType: "text"});          
  }

  // get doctor profile
  getUserApi(uid):Observable<any> {
    console.log("api check-->");
    return this.httpClient.get(`${DOCTOR_URL}/profile/`+uid, {responseType: "json"});
  }

  // update doctor profile
  docUpdate(values: any, user: any): Observable<any> {
    ////console.log(values);
    var url=DOCTOR_URL;
    console.log(values, user);
    return this.httpClient.put(`${DOCTOR_URL}/update_profile`, values, {responseType: 'text'});
  }

  // get chat by userid and circle code
  getChatByUserid(userid,circleCode) {
    let url = LOOKUP_URL+"/scgetchatdeatilssc/"+userid+"/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }
  getNotesByDxid(dxid){
    var epurl=DOCTOR_URL+"/notesdx/"+dxid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType:"json"});
  }

  // get prescription by userid, circle code for doc notes
  prescriptionByUserCircleJson(userid: any, ccode:any): Observable<any> {
    var epurl=MEDICINE_URL+"/prescriptionusercirclecodedjson/"+userid+"/"+ccode;
    console.log("url......"+epurl);
    console.log(JSON.stringify(userid, ccode));
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get circle admin prescription notes
  getCircleNotes(userid:any, ccode:any){
    var epurl=DOCTOR_URL+"/notescircle/"+userid+"/"+ccode;
    console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType:"json"});
  }

  // get prescription for notes by uid, ocid, circle code 
  getPrescriptionsUserDocCcodeJSON(userid:any, docid:any, ccode:any): Observable<any> {
    var epurl=MEDICINE_URL+"/dxhistoryuserdocccode/"+userid+"/"+docid+"/"+ccode;
    console.log("url......"+epurl);
    //console.log(userid);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get circle member prescription notes
  getNotesCircleMember(userid:any, docid:any, ccode:any){
    var epurl=DOCTOR_URL+"/notescirclemember/"+userid+"/"+docid+"/"+ccode;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType:"json"});
  }

  getNotes(userid:any, docid:any){
    var epurl=DOCTOR_URL+"/notes/"+userid+"/"+docid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType:"json"});
  }

  // update multiple notes
  updateDocListNotes(values:any){
    var epurl=DOCTOR_URL+"/updatelistnotes";
    //console.log("url......"+epurl);
    //console.log("send Diagnosis --"+JSON.stringify(values));
    return this.httpClient.put(epurl, values, {responseType: "text"});
  }

  updatePrescFrmNotes(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/updatepresccomobs";
    //console.log("url......"+epurl);
    //console.log(JSON.stringify(values));
    return this.httpClient.put(epurl, values, {responseType: "text"});
  }

  // get followup between doctor chat detail 
  getFollowupBwDocChatDetail(obj:any): Observable<any> {
    let url = FOLLOWUP_URL+"/getdocchatdetail";
    return this.httpClient.put(url, obj, {responseType: "json"});
  }

  // save circle doc bank detail
  saveDocBankDetail(obj)
  {
    var epurl=DOCTOR_URL+"/savecircledocbank";
    console.log("validate post obj",obj);
    return this.httpClient.post(epurl, obj, {responseType: "json"});
  }

  //	get waiting room fee and pt count of doctor
  getWaitingRoomFeeAndPtCount(docuid,tenantid,date){
    var epurl=SWASTHPLUS+"/waitingroomfeeandptcount/"+docuid+"/"+tenantid+"/"+date;
    console.log("url", epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  updateDocProfileUpdate(obj){
    var epurl=DOCTOR_URL+"/update_profile";
    console.log("url......"+epurl);
    return this.httpClient.put(epurl, obj, {responseType: "text"});
  }

  // get IFSCcode detail 
  getIfscCheckRazorpay(ifsccode){
    var epurl="https://ifsc.razorpay.com/"+ifsccode;
    console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  addBankAccToRazorpay(searchTerm){
    var epurl="";
    epurl=FOLLOWUP_URL+"/addbankacc/"+this.razorpay_mode;
    console.log("url......"+epurl);
    return this.httpClient.post(epurl, searchTerm, {responseType: "json"});
  }

  // get circle doc bank detail
  getDocBankDetail(docid)
  {
    var epurl=DOCTOR_URL+"/getdocbank/"+docid;
    console.log(epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get followup plans patient subscription
  getFollowupsPlanForPtSubscp(ccode, docuid, status): Observable<any> {
    var epurl=FOLLOWUP_URL+"/getplanfrsubscp/"+ccode+"/"+docuid+"/"+status;
    console.log("epurl---"+epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // check circle subscribed by patient or not
  checkPatientSubscribedInCircle(userid,circlecode) {
    let url = FOLLOWUP_URL+"/checkpatientsubscribedincircle/"+userid+"/"+circlecode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // create subscribe entry before pay patientt circle, chat, chatrestrict, subscribehistory
  createSubscribeBeforePay(subscribeObj, subscpType) {
    var epurl=FOLLOWUP_URL+"/savescriptionobjcircle/"+subscpType+"/"+this.razorpay_mode;
    return this.httpClient.put(epurl, subscribeObj, {responseType: "json"});
  }

  // update subscribe entry after pay patientt circle, chat, chatrestrict, subscribehistory
  updateSubscribeDetailAfterPay(subscribeObj) {
    var epurl=FOLLOWUP_URL+"/upatescriptionobj";
    return this.httpClient.post(epurl, subscribeObj, {responseType: "text"});
  }

  // get all circle with admin profile
  getAllCircleAdminProfile() {
    var epurl=FOLLOWUP_URL+"/getallcircleadminpro";
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get Patient Profile
  getCirclePatientProfile(patientuid)
  {
    var epurl=FOLLOWUP_URL+"/getcirclepatientdetail/"+patientuid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // new user sign up
  circlePatientSignup(values: any): Observable<any> {
    return this.httpClient.post(`${FOLLOWUP_URL}/circlepatientsignup`, values, {responseType: 'text'});      
  }

  // check circle patient mobileno, email
  checkPtMobRegEmail(obj)
  {
    var epurl=FOLLOWUP_URL+"/checkptmobemail";
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  // check circle patient mobileno, email
  getCirclePatientProfileByMobno(mobilenumber)
  {
    var epurl=FOLLOWUP_URL+"/getcircleptbymobno/"+mobilenumber;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  getBillBySearchTerms(searchTerm){
    var epurl=BILL_URL+"/billbysearchterm";
    return this.httpClient.put(epurl, searchTerm, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getBillReport(searchTerm){
    var epurl=BILL_URL+"/billreport";
    return this.httpClient.put(epurl, searchTerm, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getBillReportbranch(searchTerm){
    var epurl=BILL_URL+"/billreportbranch";
    return this.httpClient.put(epurl, searchTerm, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getBillPaymentReport(searchTerm){
    var epurl=BILL_URL+"/billreportbypaymentmode";
    return this.httpClient.post(epurl, searchTerm, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getBillBySearchTermsPeak(searchTerm){
    var epurl=BILL_URL+"/billbysearchtermpeak";
    return this.httpClient.put(epurl, searchTerm, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getBillEditHistory(billId) {
    var epurl=BILL_URL+"/geteditbillhistory/"+billId;
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getPtBillRecord(uhid,tenantid){
    var epurl=BILL_URL+"/getptbillrecord/"+uhid+"/"+tenantid;
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  addBillingStaff(billobj:any, docid:any, curdate:any){
    var epurl=BILL_URL+"/billstaff/"+curdate+"/"+docid;
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, billobj, {responseType: "json"});
  }

  updateBillingStatus(billId:any, docid:any, curdate:any){
    var epurl=BILL_URL+"/billStatus/"+billId+"/"+curdate+"/"+docid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // update bill and return datas
  updateBillByID(billObj:any, docid:any, curdate:any){
    var epurl=BILL_URL+"/billupd/"+curdate+"/"+docid;
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, billObj, {responseType: "json"});
  }

  // return user detail using hospital id
  getUserListByTenantID(tenantid:any){
    var epurl=PATIENT_URL+"/hospuserslist/"+tenantid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // add bill with amountt list
  addBillAndAmt(billobj:any){
    var epurl=BILL_URL+"/addbillamt";
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, billobj, {responseType: "json"});
  }

  // update and return only result
  updateBilling(billObj:any){
    var epurl=BILL_URL+"/billUpdate";
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, billObj, {responseType: "json"}); 
  }

  // update edit bill
  updateEditedBill(billObj:any){
    var epurl=BILL_URL+"/updateeditedbill";
    //console.log("url......"+epurl);
    return this.httpClient.put(epurl, billObj, {responseType: "text"}); 
  }


  /* save pay list by bill id */
  updatePayListBillId(payList:any){
    var epurl=BILL_URL+"/addpaylist";
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, payList, {responseType: "json"});
  }

  /* save pay list by bill id */
  updatePayListBillIdNew(payList:any,paymentmode:any,tenantid:any,docuserID:any){
    var epurl=BILL_URL+"/addpaylistnew/"+paymentmode+"/"+tenantid+"/"+docuserID;
    console.log("url......"+epurl);
    return this.httpClient.post(epurl, payList, {responseType: "json"});
  }

  // get bill payment list by tenant id
  getBillListByTid(tenantid:any){
    var epurl=BILL_URL+"/billlist/"+tenantid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get bill payment list by tenant id
  getBillListByTidswasthnuo(tenantid:any){
    var epurl=BILL_URL+"/billlistswasthnuo/"+tenantid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get bill payment list by tenant id
  getBillListByTidBranch(tenantid:any,branchcode:any){
    var epurl=BILL_URL+"/billlistbranch/"+tenantid+"/"+branchcode;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get billing payment list by bill id
  getBillPayListByBid(billid): Observable<any> {
    var epurl=BILL_URL+"/paylistbid/"+billid;
    console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get receptionist profile and 
  getRecepProfileAndHosProfile(recpuid: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${SWASTHCONFIG.RECP_URL}/getrecphosprofile/`+recpuid, {responseType: "json"});
  }

  // get receptionist profile and 
  getNurseProfileAndHosProfile(nurseuid: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${SWASTHCONFIG.NURSE_URL}/getnursehosprofile/`+nurseuid, {responseType: "json"});
  }

  // get receptionist profile and 
  getPharmacyProfileAndHosProfile(pharuid: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${SWASTHCONFIG.PHARMACY_URL}/getpharmacyhosprofile/`+pharuid, {responseType: "json"});
  }

  // Bill statistics
  getBillStatistics(billObj: any) { 
    var epurl=BILL_URL+"/billstatistics";       
    //console.log(epurl);    
    return this.httpClient.put(epurl,billObj, {responseType: "json"}); 
  }

  // update video appt
  updateVideoAppt(apptList) {
    let url = SWASTHPLUS+"/updateswasthvideoappt";
    return this.httpClient.post(url,apptList, {responseType: "text"});
  }

  deactivatelogin(values: any): Observable<any> {    
    return this.httpClient.post(`${SERVER_URL}/login`, values, {responseType: 'text'});            
  }

  changePassAllUser(value:any, userid:any){
    let url=PATIENT_URL+"/changePassAll/"+value+"/"+userid;
    ////console.log(url);
    return this.httpClient.put(url, {responseType: 'text'});
  }

  // Make Voice Call
  makeVoiceCall(drMobile, ptMobile)
  {
    var epurl=LOOKUP_URL+"/makeaudiocall/"+drMobile+"/"+ptMobile;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  //	get all patient list in waiting room
  getAllWaitingRoomPt(docuid,tenantid,date){
    var epurl=SWASTHPLUS+"/getallwaitingroompt/"+docuid+"/"+tenantid+"/"+date;
    console.log("url", epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  //	swasth single doc video appt waiting room pt save
  savePtVideoWaitingRoom(videoobj) {
    var epurl=SWASTHPLUS+"/savevideoapptinwaitingroom";
    return this.httpClient.post(epurl, videoobj, {responseType: 'text'});
  }

  // Send Prescription SMS to Patient
  sendPrescriptionSMSToPatient(prescriptionLink, patientName, doctorName, ptMobile) {
    var epurl=LOOKUP_URL+"/sendprescriptiontopatient/"+prescriptionLink+"/"+ptMobile+"/"+doctorName+"/"+patientName;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }
  
  // Make Voice Call
  getPtListByTenantId(tenantId) {
    var epurl=PATIENT_URL+"/getptlistbyhospital/"+tenantId;
    return this.httpClient.get(epurl, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // Make Voice Call
  getPtListByTenantIdBranch(tenantId,branchcode) {
    var epurl=PATIENT_URL+"/getptlistbyhospitalbranch/"+tenantId+"/"+branchcode;
    return this.httpClient.get(epurl, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get recent vital by uhid tenantid
  getRecentVitalByUhid(uhid, tenantId) {
    var epurl=VITAL_URL+"/vitalRecentUhid/"+uhid+"/"+tenantId;
    return this.httpClient.get(epurl, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get patient list with prescription
  getPtListWithPrescription(docuid, tenantid) {
    var epurl=PRESCRIPTION_URL+"/ptlistwithprescription/"+docuid+"/"+tenantid;
    return this.httpClient.get(epurl, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get patient list with prescription branch
  getPtListWithPrescriptionBranch(docuid, tenantid,branchcode) {
    var epurl=PRESCRIPTION_URL+"/ptlistwithprescriptionbranch/"+docuid+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get patient list with prescription by tenant id
  getPtListWithPrescriptionByTenantID(doctorid,tenantid) {
    var epurl=PRESCRIPTION_URL+"/ptlistwithprescriptionbytenant/"+doctorid+"/"+tenantid;
    return this.httpClient.get(epurl, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // get patient list with prescription
  getPtListWithPresBySearch(searchObj) {
    var epurl=PRESCRIPTION_URL+"/ptlistwithpresbysearch";
    console.log("epurl", epurl);
    return this.httpClient.put(epurl, searchObj, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  //	get patient prescription history
  getPtPastPrescriptionList(prescObj) {
    var epurl=PRESCRIPTION_URL+"/getptpastprescriptionlist";
    return this.httpClient.put(epurl, prescObj, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getPatientAllPrescriptionList(userid,tenantid){
    var epurl=PRESCRIPTION_URL+"/getptpastprescriptionlistbyuserid/"+userid+"/"+tenantid;
    return this.httpClient.put(epurl, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  // add presc notes
  addSwasthPlusPrescNotes(noteObj) {
    var epurl=PRESCRIPTION_URL+"/addswasthplusprescnotes";
    return this.httpClient.post(epurl, noteObj, {responseType: 'json'});
  }

  //	update prescription complaint, observation
  updateSwasthPlusPrescObserComplaint(presc) {
    var epurl=PRESCRIPTION_URL+"/updateswasthplusprescobsercomplaint";
    return this.httpClient.post(epurl, presc, {responseType: 'text'});
  }

  // upload prescription document
  uploadPrescription(uploadObj) {
    var epurl=PRESCRIPTION_URL+"/uploadprescription";
    return this.httpClient.post(epurl, uploadObj, {responseType: 'text'});
  }

  // get upload image by keyname list
  getUploadImgByDocumentId(keyNameObj) {
    var epurl=PRESCRIPTION_URL+"/getuploadimgbydocumentid";
    console.log("epurl", epurl);
    return this.httpClient.put(epurl, keyNameObj, {responseType: 'json'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    ) 
  }

  //	get template list of doctor
  getDoctorTemplateList(docuid, tenantid) {
    var epurl=PRESCRIPTION_URL+"/doctortemplatelist/"+docuid+"/"+tenantid;
    console.log("epurl", epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  //	get template list of doctor branch
  getDoctorTemplateListbranch(docuid, tenantid,branchcode) {
    var epurl=PRESCRIPTION_URL+"/doctortemplatelistbranch/"+docuid+"/"+tenantid+"/"+branchcode;
    console.log("epurl", epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // update edited prescription
  updatePtPrescription(updateObj) {
    var epurl=PRESCRIPTION_URL+"/updatePrescription";
    return this.httpClient.post(epurl, updateObj, {responseType: 'json'});
  }

  // add bill without amountt list and update appointnment status bill only case
  addBillingUpdateAppt(billobj:any){
    var epurl=BILL_URL+"/addbillupdateappt";
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, billobj, {responseType: "json"});
  }

  // save ticket for hospital support
  saveTicketHospSupport(keyname, failedapi, frompage) {
    let ticketObj:any={
      postJsonValue: keyname.split("=")[1],
      createdDate: new Date(),
      ticketStatus: "pending",
      ticketClosedBy: "",
      failedApi: failedapi,
      fromPage: frompage
    }
    let profile:any=SWASTH_CONST.USER_PROFILE;
    console.log("profile", profile, SWASTH_CONST.USER_PROFILE, SWASTH_CONST.USER_TYPE);
    if(SWASTH_CONST.USER_TYPE=="Doctor") {
      ticketObj.userID= profile.docUserID;
      ticketObj.createdByUserID= profile.docUserID;
    } else if(SWASTH_CONST.USER_TYPE=="Nurse") {
      ticketObj.userID= profile.nurseUserID;
      ticketObj.createdByUserID= profile.nurseUserID;
    } else {
      ticketObj.userID= profile.recpUserID;
      ticketObj.createdByUserID= profile.recpUserID;
    }
    ticketObj.tenantID= SWASTH_CONST.HOSPITALTENANTURL;
    ticketObj.userRole= SWASTH_CONST.USER_TYPE;
    
    var epurl=SUPPORT_URL+"/savetickethospsupport";
    console.log("epurl", epurl, ticketObj);
    return this.httpClient.put(epurl, ticketObj, {responseType: 'text'})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    ) 
  }  

  sendTicketToSupport(postObj:any) {
    console.log("body", postObj);
    let keyname = "swasthplusticket" + Math.floor(100000 + Math.random() * 900000) + ".txt";
    let blob = new Blob([JSON.stringify(postObj)], { type: 'application/text' });
    console.log("blob", blob);
    let body = new FormData();
    body.append('uploadfile', blob);
    body.append('keyname', keyname);
    console.log("body", body);
    let UPLOAD_URL:any = SWASTHCONFIG.UPLOAD_URL;
    return this.httpClient.post(UPLOAD_URL+ '/api/file/lockdownupload', body,{responseType: 'text'});
  }

  // Send Video Appointment SMS to Doctor

  sendPlusSMS(templateId,mobileNo, var0, var1, var2, var3)
  {
    let postObj:any = JSON.stringify({
      template_id: templateId,
      short_url: '1',
      recipients: [{mobiles: mobileNo, var: var0, var1: var1, var2: var2, var3:var3}]
    });
    let obj:any={
      post:postObj
    }
    var epurl=LOOKUP_URL+"/sendmessage91";
    console.log("body", epurl, postObj);
    return this.httpClient.put(epurl, obj, {responseType: 'json'});
  }

  // Send bill SMS to Patient

  sendPlusBillSMSToPatient(ptMobile, patientName, pdflink, doctorName)
  {
    var epurl=LOOKUP_URL+"/sendplusbillsmspatient/"+ptMobile+"/"+patientName+"/"+pdflink+"/"+doctorName;
    console.log("body", epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // Send Video Appointment SMS to Doctor

  sendPlusApptSMSToPatient(ptMobile, patientName, scheduleDate, doctorName,hospitalPhoneNumber)
  {
    var epurl=LOOKUP_URL+"/sendplusclinicappointmentsmspatient/"+ptMobile+"/"+patientName+"/"+scheduleDate+"/"+doctorName+"/"+hospitalPhoneNumber;
    console.log("body", epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  getChatGptResponse(msg:any) {
    var epurl=CHATGPT_URL+"/generatechatgptmsg";
    let body = new FormData();
    body.append("message", msg.message);
    body.append("type", msg.type);
    body.append("page", msg.page);
    // let header:HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(epurl, body, {responseType: "json"});
  }

  saveChatGptResponseToTemplate(template:any) {
    var epurl=CHATGPT_URL+"/savetemplate";
    let body = new FormData();
    console.log("body", template.templateName, "---", template.chatgptMsg)
    body.append("template_name", template.templateName);
    body.append("chatgpt_msg", template.chatgptMsg);
    body.append("doctor_edited", template.doctorEdited)
    return this.httpClient.post(epurl, body, {responseType: "text"});
  }

  saveMedicineSuggestion(medSugObj:any) {
    var epurl=PRESCRIPTION_URL+"/savemedicinesuggestion";
    return this.httpClient.post(epurl, medSugObj, {responseType: "text"}); 
  }

  getMedicineSuggestion(searchObj:any) {
    var epurl=PRESCRIPTION_URL+"/getmedicinesuggestion";
    return this.httpClient.put(epurl, searchObj,{responseType: 'json'}); 
  }

  getMemberAccessOfDoctor(docuid:any, tenantId:any) {
    var epurl=DOCTOR_URL+"/getaccessuser/"+docuid+"/"+tenantId;
    return this.httpClient.get(epurl,{responseType: 'json'}); 
  }

  getMemberAccessOfDoctorBranch(docuid:any, tenantId:any,branchcode:any) {
    var epurl=DOCTOR_URL+"/getaccessuserbranch/"+docuid+"/"+tenantId+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'}); 
  }

  provideAccessToMemb(accessObj:any) {
    var epurl=DOCTOR_URL+"/createaccess";
    return this.httpClient.post(epurl, accessObj, {responseType: 'json'}); 
  }

  revokeAccessToMemb(accessId:any, docuid:any, tenantid:any, mode:any) {
    var epurl=DOCTOR_URL+"/removeaccess/"+accessId+"/"+docuid+"/"+tenantid+"/"+mode;
    return this.httpClient.post(epurl, {responseType: 'json'}); 
  }
  
  updateBillDocumentKey(billId:any, documentkey:any){
    var epurl=BILL_URL+"/updatebilldocumentkey/"+billId+"/"+documentkey;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "text"});
  }

  getWeeklyReport(postobj:any) {
    if(postobj.branchCode==null)
    {
      var epurl=DOCTOR_URL+"/getweekreportfordoctor";
    }
    else
    {
      var epurl=DOCTOR_URL+"/getweekreportfordoctorbranch";
    }
    
    return this.httpClient.put(epurl, postobj, {responseType: 'json'}); 
  }

  

  getMedicineTypeMasterList(doctoruid:any)
  {
    var epurl=PRESCRIPTION_URL+"/getmedicinetypemasterlist/"+doctoruid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }


  getDoseRegimenMasterList(doctoruid:any)
  {
    var epurl=PRESCRIPTION_URL+"/getdoseregimenmasterlist/"+doctoruid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  addMedicineType(postobj:any)
  {
    var epurl=PRESCRIPTION_URL+"/addmedicinetypemaster";
    return this.httpClient.post(epurl,postobj,{responseType: 'json'});

  }

  addDoseRegimen(postobj:any)
  {
    var epurl=PRESCRIPTION_URL+"/adddoseregimenmaster";
    return this.httpClient.post(epurl,postobj,{responseType: 'json'});
  }

  getLabNameMasterList()
  {
    var epurl=PRESCRIPTION_URL+"/getlabemasterlist";
    return this.httpClient.get(epurl,{responseType: 'json'});
  }


  getradiologynamemasterlist()
  {
    var epurl=PRESCRIPTION_URL+"/getradiologynamemasterlist";
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getLabTypeMasterList()
  {
    var epurl=PRESCRIPTION_URL+"/getlabtypemasterlist";
    return this.httpClient.get(epurl,{responseType: 'json'});
  }


  getradiologybodypartmasterlist()
  {
    var epurl=PRESCRIPTION_URL+"/getradiologybodypartmasterlist";
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getradiologytypemasterlist()
  {
    var epurl=PRESCRIPTION_URL+"/getradiologytypemasterlist";
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getTreatmentPlanListMaster(tenantid:any)
  {
    var epurl=PRESCRIPTION_URL+"/gettreatmentplan/"+tenantid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getInvestigationsList(tenantid:any)
  {
    var epurl=PRESCRIPTION_URL+"/getinvestigations/"+tenantid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getGenericName(medicineName:any){
    var epurl=PRESCRIPTION_URL+"/getgenericname/"+medicineName;
    return this.httpClient.get(epurl, {responseType: "text"});
  }


  getAppointmentListbydoc(docuserid,tenantid,month,year) {
    var epurl = SWASTHPLUS + "/getdocallappointmentlist/"+docuserid+"/"+tenantid+"/"+month+"/"+year;
    return this.httpClient.get(epurl, {responseType: "json"})    
  }

  getAppointmentListbydocstartdateenddate(docuserid,tenantid,startdate,enddate) {
    var epurl = SWASTHPLUS + "/getdocallappointmentliststdateendate/"+docuserid+"/"+tenantid+"/"+startdate+"/"+enddate;
    return this.httpClient.get(epurl, {responseType: "json"})    
  }

  getAppointmentListbydocstartdateenddatebranch(docuserid,tenantid,startdate,enddate,branchcode) {
    var epurl = SWASTHPLUS + "/getdocallappointmentliststdateendate/"+docuserid+"/"+tenantid+"/"+startdate+"/"+enddate+"/"+branchcode;
    return this.httpClient.get(epurl, {responseType: "json"})    
  }

  getAppointmentListbytenentstartdateenddate(tenantid,startdate,enddate) {
    var epurl = SWASTHPLUS + "/getallappointmentliststdateendate/"+tenantid+"/"+startdate+"/"+enddate;
    return this.httpClient.get(epurl, {responseType: "json"})    
  }

  getAppointmentListbytenentstartdateenddatebranch(tenantid,startdate,enddate,branchcode) {
    var epurl = SWASTHPLUS + "/getallappointmentliststdateendatebranch/"+tenantid+"/"+startdate+"/"+enddate+"/"+branchcode;
    return this.httpClient.get(epurl, {responseType: "json"})    
  }

  updateAppointment(apptid,apptdate,appttime) {
    var epurl=SWASTHPLUS+"/updateappointment/"+apptid+"/"+apptdate+"/"+appttime;
    return this.httpClient.get(epurl, {responseType: 'text'}); 
  }

  getAppointmentbyid(apptid) {
    var epurl = SWASTHPLUS + "/getappointmentbyid/"+apptid;
    return this.httpClient.get(epurl, {responseType: "json"})    
  }

  getappointmentbydatetime(apptdate,appttime,docuserid){
    var epurl=SWASTHPLUS+"/getappointmentbydatetime/"+apptdate+"/"+appttime+"/"+docuserid;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }


  updateAppointmentViewMode(docuserid,apptViewMode) {
    var epurl=DOCTOR_URL+"/updateappointmentview/"+docuserid+"/"+apptViewMode;
    return this.httpClient.get(epurl, {responseType: 'text'}); 
  }

  updateAppointmentViewModefornurse(nurseuid,apptViewMode) {
    var epurl=NURSE_URL+"/updateappointmentmode/"+nurseuid+"/"+apptViewMode;
    return this.httpClient.get(epurl, {responseType: 'text'}); 
  }

  updateAppointmentViewModeforrecep(recepuid,apptViewMode) {
    var epurl=RECP_URL+"/updateappointmentmode/"+recepuid+"/"+apptViewMode;
    return this.httpClient.get(epurl, {responseType: 'text'}); 
  }

  updateEventColorCode(docuserid,colorcode,cssclass) {
    var epurl=DOCTOR_URL+"/updateappointmenteventcolor/"+docuserid+"/"+colorcode+"/"+cssclass;
    return this.httpClient.get(epurl, {responseType: 'text'}); 
  }

  getDoctorProfileByColorCode(tenantid,colorcode){
    var epurl=DOCTOR_URL+"/getprofilebycolorcode/"+tenantid+"/"+colorcode;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // save case photos
  saveCasePhotos(casephotoobj){
    var epurl=PRESCRIPTION_URL+"/savecasephotos";
    return this.httpClient.post(epurl, casephotoobj, {responseType: 'json'});
  }

  // get case photo list by user
  getCasePhotosByUser(userid,tenantid,casePhotoid){
    var epurl=PRESCRIPTION_URL+"/getcasephotobyuser/"+userid+"/"+tenantid+"/"+casePhotoid;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // get case photo list by user
  getCasePhotosByDate(userid,tenantid,createdDate){
    var epurl=PRESCRIPTION_URL+"/getcasephotobydate/"+userid+"/"+tenantid+"/"+createdDate;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  getHospitalPateintProfile(userid:any,tenantid:any):Observable<any>{
    var epurl=PATIENT_URL+"/gethospitalpatient/"+userid+"/"+tenantid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get bill payment list by tenant id like char
  getBillListByTidLike(tenantid:any, char){
    var epurl=BILL_URL+"/billlistlikechar/"+tenantid+"/"+char;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // save case photos
  saveApptTreatmentList(treatmentListobj:any){
    var epurl=SWASTHPLUS+"/saveappttreatmentlist";
    return this.httpClient.post(epurl,treatmentListobj, {responseType: 'json'});
  }

  // get bill payment list by tenant id like char
  getTreatmentPlanList(apptid:any){
    var epurl=SWASTHPLUS+"/gettreatmentplanlist/"+apptid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // delete appt schedular when canceled the appt

  deleteApptSchedualr(apptid:any){
    var epurl=SWASTHPLUS+"/deleteschappt/"+apptid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "text"});
  }

  // save billing due payments
  addBillDuePayments(billDueobj:any){
    var epurl=BILL_URL+"/savebillduepayments";
    return this.httpClient.post(epurl, billDueobj, {responseType: "json"});
  }

  // retrive billing due payments
  getBillDuePayments(billid:any){
    var epurl=BILL_URL+"/getbillduepaymentsbybillid/"+billid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

 // save thanks form details
 saveThanksFormDetails(clinicThanksForm:any)
 {
  var epurl=DOCTOR_URL+"/savethanksform";
  return this.httpClient.post(epurl, clinicThanksForm, {responseType: 'json'}); 
 }

 // get thanks form detaild by mobile number

 getClinicThanksForm(ptmobile:any){
  var epurl=DOCTOR_URL+"/getclinicthanksform/"+ptmobile;
  return this.httpClient.get(epurl, {responseType: "json"});
}

// send thanks form message to patient

sendThanksFormMessage(doctorname:any,ptmobile:any,clinicphone:any,clinicwebsite:any,messageType:any,doctoruid:any){
  var epurl=PRESCRIPTION_URL+"/sendthanksmessage/"+doctorname+"/"+ptmobile+"/"+clinicphone+"/"+clinicwebsite+"/"+messageType+"/"+doctoruid;
  return this.httpClient.get(epurl, {responseType: "json"});
}

// get billing details by appt id

getBillingDetailsByApptID(apptid:any)
{
  var epurl=BILL_URL+"/getbilldetailsbyapptid/"+apptid;
  return this.httpClient.get(epurl, {responseType: "json"});
}

// update thanks message latest send date

updateThanksDateTime(ptmobile)
  {
    // console.log("pdfObj", pdfObj);
    var epurl=DOCTOR_URL+"/updatelatestdatetime/"+ptmobile;
    return this.httpClient.put(epurl,{responseType: 'text'});
  }

  // update thanks message latest send date

updatePrescriptionOption(docuserid:any,option:any)
{
  // console.log("pdfObj", pdfObj);
  var epurl=DOCTOR_URL+"/updateprescriptionoption/"+docuserid+"/"+option;
  return this.httpClient.put(epurl,{responseType: 'text'});
}


 getMedicineSearch(keyword:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/medicine/"+keyword;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

getMedicineDetailsbysearchname(keyword:any,doctoruid:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/medicinesuggestionbydocuid/"+keyword+"/"+doctoruid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getMedicineDetailsbysearchnamenew(keyword:any,doctoruid:any,tenantid:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/medicinesuggestionbydocuidnew/"+keyword+"/"+doctoruid+"/"+tenantid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getMedicineDetailsbysearchnamenewbranch(keyword:any,doctoruid:any,tenantid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/medicinesuggestionbydocuidnewbranch/"+keyword+"/"+doctoruid+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getLabDetailsbysearchname(keyword:any)
  {
    var epurl=SWASTHCONFIG.LOOKUP_URL+"/lab/"+keyword;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getRadDetailsbysearchname(keyword:any)
  {
    var epurl=SWASTHCONFIG.LOOKUP_URL+"/radiology/"+keyword;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }
  getcardiologyname(keyword:any)
  {
    var epurl=SWASTHCONFIG.LOOKUP_URL+"/cardiology/"+keyword;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }
  getothername(keyword:any)
  {
    var epurl=SWASTHCONFIG.LOOKUP_URL+"/other_investigations/"+keyword;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getfinaldx(keyword:any)
  {
    var epurl=SWASTHCONFIG.LOOKUP_URL+"/diagnosis/"+keyword;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getLatestPrescription(docuid,tenantid)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/getlayestprescriptionbydocid/"+docuid+"/"+tenantid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  //	save phrmacy product
  savePharmacyProduct(videoobj) {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savePharmacyProduct";
    return this.httpClient.post(epurl, videoobj, {responseType: 'text'});
  }

  //	save phrmacy product
  savePharmacyPurchaseOrder(videoobj:any) {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savepurchaseorder";
    return this.httpClient.post(epurl, videoobj, {responseType: 'text'});
  }

  // get pharmacy product

  getPharmacyProducts(tenantid,branchCode,status,filterType)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getPharmacyProducts/"+tenantid+"/"+branchCode+"/"+status+"/"+filterType;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // delete product by product id

  deletePharmacyProduct(proid:any) {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/deactivatepharmacyproduct/"+proid;
    return this.httpClient.get(epurl, {responseType: 'text'});
  }


  getMedicineList(){
    var epurl=PHARMACY_URL+"/medicinelist";
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get pharmacy product

  getMedicineStock(productCode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getMedicineStock/"+productCode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPharmacySupplier(keyword:any,tenantid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacysupplier/"+keyword+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPharmacyProductSearch(keyword:any,tenantid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacyproduct/"+keyword+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPharmacyProductCodeSearch(keyword:any,tenantid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacyproductcode/"+keyword+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPharmacyProductByPCode(productCode:any,tenantid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacyproductbypcode/"+productCode+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  //	save phrmacy batch
  savePharmacyBatch(batchobj:any) {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savepharmacybatch";
    return this.httpClient.post(epurl, batchobj, {responseType: 'json'});
  }

  getBatchList(){
    var epurl=PHARMACY_URL+"/getbatchlist";
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  getPharmacyBatchByProductCode(productCode:any,tenantid:any,branchcode)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getbatchlistbypcode/"+productCode+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPharmacyBatchSearch(productCode:any,keyword:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacybatch/"+productCode+"/"+keyword;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  

  //	save phrmacy bill list product
  savePharmacyBillList(billList:any) {
    var epurl=SWASTHCONFIG.BILL_URL+"/savebilllist";
    return this.httpClient.post(epurl, billList, {responseType: 'text'});
  }

  //	save phrmacy supplier
  savePharmacySupplier(supplierobj:any) {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savepharmacysupplier";
    return this.httpClient.post(epurl, supplierobj, {responseType: 'text'});
  }

  // get pharmacy product

  getPharmacySupplierByTenant(tenantid,branchCode)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacysupplierbytenant/"+tenantid+"/"+branchCode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // get purchase orders

  getPurchaseOrdersByTenant(tenantid)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpurchaseorder/"+tenantid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // get purchase orders report

  getPurchaseOrders(searchobj:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacypurchaseorder/";
    return this.httpClient.put(epurl, searchobj, {responseType: 'json'});
  }

  // update pharmacy product
  updatePharmacyProduct(pharmacyObj:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/updatePharmacyProduct";
    return this.httpClient.post(epurl,pharmacyObj,{responseType: 'text'});
  }

  getPrescripedMedicinesByDxid(ptdxid:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/getprescripedmedicinesforbill/"+ptdxid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPrescripedMedicinesByDxidbranch(ptdxid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/getprescripedmedicinesforbillbranch/"+ptdxid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPharmacyProductByCode(productcode:any,tenantid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getPharmacyProductbyproductcode/"+productcode+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getBatchDetails(batch:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getbatch/"+batch;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getBatchDetailsById(batchid:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getbatchbyid/"+batchid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getOrderRecentMedicine(pcode:any,tenantid:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getmedicinerecentorder/"+pcode+"/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // delete batch
  deleteBatch(batch:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/deletebatch/"+batch;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // save pharmacy product type

  savePharmacyProductType(pharmacyProductType:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savepharmacyproducttype";
    return this.httpClient.post(epurl, pharmacyProductType, {responseType: 'text'});
  }

  getPharmacyProductType(tenantid:any,branchcode)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacyproducttype/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // delete product type
  deleteProductType(id:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/deleteproducttype/"+id;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // save pharmacy medicine type

  savePharmacyMedicineType(pharmacyMedicineType:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savepharmacymedicinetype";
    return this.httpClient.post(epurl, pharmacyMedicineType, {responseType: 'json'});
  }

  getPharmacyMedicineType(tenantid:any,branchcode)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacymedicinetype/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getPharmacyHsn(tenantid:any,branchcode)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacyhsn/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // delete medicine type
  deleteMedicineType(id:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/deletemedicinetype/"+id;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // save pharmacy pack type

  savePharmacyPackType(pharmacyPackType:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savepharmacypacktype";
    return this.httpClient.post(epurl, pharmacyPackType, {responseType: 'json'});
  }

  getPharmacyPackType(tenantid:any,branchcode)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacypacktype/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // save pharmacy hsn code

  savePharmacyHsn(hsn:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/savepharmacyhsn";
    return this.httpClient.post(epurl, hsn, {responseType: 'json'});
  }

  getPharmacyHsnCode(tenantid:any,branchcode)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacyhsn/"+tenantid+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  // delete hsn master
  deleteHsn(id:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/deletehsn/"+id;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // delete medicine type
  deletePackType(id:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/deletepacktype/"+id;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  getPharmacyPurchaseOrderItems(poid:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacypurchaseorderitems/"+poid;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getMedicineOrderItemsReport(searchTerm){
    var epurl=PHARMACY_URL+"/getpharmacypurchaseorderitemssearch";
    return this.httpClient.put(epurl, searchTerm, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getMedicineSalesItemsReport(searchTerm){
    var epurl=PHARMACY_URL+"/getbillitems";
    return this.httpClient.put(epurl, searchTerm, {responseType: "json"})
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getPharmacyProductByName(checkProductObj:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getPharmacyProductbyname/";
    return this.httpClient.post(epurl,checkProductObj,{responseType: 'json'});
  }

  getRecentPrescriptionByUID(uhid:any,docuid:any,tenant:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/recentprescription/"+uhid+"/"+docuid+"/"+tenant;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getRecentPrescriptionByUIDBranch(uhid:any,docuid:any,tenant:any,branchcode:any)
  {
    var epurl=SWASTHCONFIG.PRESCRIPTION_URL+"/recentprescriptionbranch/"+uhid+"/"+docuid+"/"+tenant+"/"+branchcode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getProductReport(tenantid,branchCode,filtertype)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getpharmacyproductsreport/"+tenantid+"/"+branchCode+"/"+filtertype;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

  getBranchDetails(branchCode:any)
  {
    var epurl=SWASTHCONFIG.PHARMACY_URL+"/getbranchmasterbycode/"+branchCode;
    return this.httpClient.get(epurl,{responseType: 'json'});
  }

}
